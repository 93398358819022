const event = (name, data) => {
    gtag('event', name, data)
}

const gtag = function () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(arguments)
}

const consent = (type, consents) => {
    gtag('consent', type, consents)
}

const content = (level, group, type) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        'content-level': level,
        'content-group': group,
        'content-type': type,
    })
}

export const init = () => {
    consent('default', {
        'ad_storage': 'denied',
        'analytics_storage': 'denied',
        'ad_user_data': 'denied',
        'ad_personalization': 'denied',
        'functionality_storage': 'granted',
    })

    bergfex.content = content
    bergfex.gtag = gtag
    bergfex.gtagEvent = function (name, category, prefix, suffix) {
        const eventPrefix = (prefix) ? prefix : ''
        const eventSuffix = (suffix) ? suffix : '_lang'

        event(name, {
            'event_category': category,
            'event_label': eventPrefix + '-' + eventSuffix
        })
    }

    bergfex.gtagEventWithData = function(name, data) {
        event(name, data)
    }
}

export default {event, consent, gtag}
