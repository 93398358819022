import scrollLock from '../../abstracts/scrollLock'
import lightbox from './lightbox'
import tagmanager from '../../abstracts/tagmanager'

const modalAjax = (id = 'modal') => ({
    open: false,
    cache: [],
    history: [],
    loading: false,
    rendering: false,
    error: false,
    contentRoot: null,
    contentHeight: null,
    header: null,
    id: id,
    isSearchModal: false,
    ignoreHistory: false,

    trackingEvent: false,
    trackingCategory: null,

    init() {
        this.contentRoot = this.$root.querySelector('[x-ref="content"]')
        window.addEventListener('popstate', () => {
            this.close()
        })
    },

    openModal(data) {
        if (data.id === this.id) {
            this.toggle(data.endpoint, data.trackingEvent, data.trackingCategory)
        }
    },

    closeModal(id) {
        id === this.id && this.close()
    },

    async toggle(endpoint, trackingEvent = false, trackingCategory = null) {
        if(!this.open) {
            history.pushState(this.open, "", "")
        }
        this.trackingEvent = trackingEvent
        this.trackingCategory = trackingCategory

        this.resetToStartPage(endpoint)

        if (!this.open) {
            await this.loadPage({detail: endpoint})
            this.contentHeight = null
            await this.$nextTick()
            setTimeout(() => {
                if (this.contentRoot.parentElement.offsetHeight > 0) {
                    this.contentHeight = this.contentRoot.parentElement.offsetHeight
                }
            }, 300)

            lightbox()
        }

        this.open = !this.open
        this.track()
        scrollLock.toggle(this.open)
    },

    close(goBack) {
        if(goBack) {
            window.history.back()
        }
        this.open = false
        this.$store.modal.close();
        scrollLock.toggle(this.open)
        this.track()
        this.$dispatch('after-modal-close', { name: this.id })
        this.resetToStartPage()
    },

    async resetToStartPage(endpoint = null) {
        if (endpoint && this.history.length === 1 && this.history[0] !== endpoint) {
            this.history = []
            return
        }

        if (this.history.length <= 1) {
            return
        }
        await this.loadPage({detail: this.history[0]}, false)
        this.history.splice(1)
    },

    async pageBack() {
        await this.loadPage({detail: this.history[this.history.length - 2]}, false)
        this.history.pop()
    },

    async loadPage({detail}, history = true) {
        if (this.loading) {
            return
        }

        if (this.contentRoot.innerHTML.replace(/\s/g, '').length === 0) {
            this.history = [];
        }

        if (this.history.length > 0 && (detail === this.history[this.history.length - 1])) {
            return
        }

        this.error = false
        this.loading = true

        const cachedRoute = this.getCache(detail)
        if (cachedRoute) {
            this.renderPage(detail, cachedRoute.cache, history)
            this.loading = false
            return
        }

        try {
            const response = await fetch(detail)
            const jsonData = await response.json()
            if (!jsonData.result || jsonData.error) {
                this.error = true
                this.loading = false
                return
            }

            this.renderPage(detail, jsonData.result, history)
            this.cache.push({
                route: detail,
                cache: jsonData.result,
            })
            this.loading = false
        } catch (error) {
            this.error = true
            this.loading = false
        }
    },

    getCache(route) {
        return this.cache.find((item) => (item.cache && item.route === route))
    },

    renderPage(route, content, history) {
        this.rendering = true
        if (!this.contentRoot.firstElementChild) {
            this.contentRoot.append(document.createElement("div"))
        }
        Alpine.morph(this.contentRoot.firstElementChild, content)

        history && this.history.push(route)

        this.$dispatch('modal-rendered-page', {route: route, root: this.contentRoot.firstElementChild})

        this.rendering = false
    },

    setHeader({detail}) {
        this.header = detail
    },

    track() {
        if(!this.trackingEvent) {
           return
        }

        tagmanager.event(this.trackingEvent, {Selection: this.trackingCategory + (this.open ? '_open' : '_close')})
    }
})

export default modalAjax
