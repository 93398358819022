const modalStore = {
    openId: '',

    open(id, endpoint = '', trackingEvent = false, trackingCategory = null) {
        this.openId = id

        if (endpoint.length > 0) {
            this.openAjax(id, endpoint, trackingEvent, trackingCategory)
            return
        }

        this.openStatic(id)
    },

    openStatic(id) {
        window.dispatchEvent(new CustomEvent('modal-open', {detail: id, 'bubbles': true}))
    },

    openAjax(id, endpoint, trackingEvent, trackingCategory) {
        window.dispatchEvent(new CustomEvent('modal-open', {
            detail: {
                id: id,
                endpoint: endpoint,
                trackingEvent: trackingEvent,
                trackingCategory: trackingCategory
            }, 'bubbles': true
        }))
    },

    close(id) {
        window.dispatchEvent(new CustomEvent('modal-close', {detail: id, 'bubbles': true}))
        this.openId = ''
    },

    isOpen(id) {
        return id === this.openId
    }
}

export default modalStore
