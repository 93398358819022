import {getCookie} from '../abstracts/cookie'

const cookieBanner = () => {

    // disable, if already accepted cookie consent
    if(getCookie('euconsent-v2')) {
        return
    }

    const identifier = {
        banner: '[id^="sp_message_container"]',
    }

    let style = null

    const init = () => {
        document.addEventListener('touchstart', interaction)
        document.addEventListener('click', interaction)
        document.addEventListener('scroll', interaction)
        document.addEventListener('mousemove', interaction)
    }

    const interaction = () => {
        document.removeEventListener('touchstart', interaction)
        document.removeEventListener('click', interaction)
        document.removeEventListener('scroll', interaction)
        document.removeEventListener('mousemove', interaction)
        if(!style) {
            return
        }

        document.head.removeChild(style)
    }

    window.addEventListener('metaTagSystemContentLoaded', () => {
        style = document.createElement("style")
        style.innerText = `
        ${identifier.banner} {
          display: none !important;
        }
        `
        document.head.appendChild(style)
    })

    window.addEventListener('metaTagSystemWindowLoadComplete', () => {
        init()
    })
}

export default cookieBanner
