import tagmanager from "../../../abstracts/tagmanager"

const skiresort = () => ({
    
    autocompleteSelect(event) {
        tagmanager.event(`skiresort-search`, {
            Category: 'skiresort',
            Skiresort: event.detail.attributes[7],
            Country: event.detail.attributes[6],
            Federalstate: event.detail.attributes[4],
        })
        
        this.$nextTick(() => {
            window.location.href = '/' + event.detail.attributes[7] + '/'
        })
    },
})

export default skiresort
