export const BREAKPOINT_XS = 421
export const BREAKPOINT_SM = 641
export const BREAKPOINT_MD = 768
export const BREAKPOINT_LG = 1060
export const BREAKPOINT_XL = 1248
export const BREAKPOINT_2XL = 1400
export const BREAKPOINT_3XL = 1600

export const isResponsiveScreen = (breakpoint = BREAKPOINT_MD) => {
    return (window.innerWidth <= breakpoint) || (window.matchMedia && window.matchMedia('only screen and (max-width: ' + breakpoint + 'px)').matches);
}

export const getMaxItemsPerGridPage = () => {
    if (window.innerWidth <= BREAKPOINT_MD) {
        return 2
    }

    if (window.innerWidth <= BREAKPOINT_LG) {
        return 3
    }

    return 4
}

export const defaultGridItems = {
    1: 8, 2: 8, 3: 9, 4: 8,
}
