import Iodine from '@caneara/iodine'

const formValidator = () => ({

    iodine: new Iodine(),

    success: false,
    isFormSubmittable: false,

    inputs: [],
    fields: {},

    init() {
        this.iodine.setDefaultFieldName(bergfex?.form?.validation.field);
        this.iodine.setErrorMessages(bergfex?.form?.validation)

        this.reset()
        this.afterInit?.()
    },

    registerInputFields() {
        this.inputs = this.$root.querySelectorAll('input[data-rules], textarea[data-rules]')
        for (const input of this.inputs) {
            this.fields[input.name] = {
                validation: {
                    error: '',
                    rule: '',
                    valid: false
                },
                blurred: false,
                value: ''
            }
        }
    },

    blur(event) {
        this.validateField(event, true)
        this.setFormSubmittable()
    },

    input(event) {
        this.validateField(event, false)
    },

    validateField(event, blurred) {
        this.fields[event.target.name].value = event.target.value
        this.fields[event.target.name].blurred = blurred
        this.fields[event.target.name].validation = this.assertField(event.target)
    },

    assertField(input) {
        return this.iodine.assert(input.value, this.getFieldRules(input))
    },

    getFieldRules(target) {
        return JSON.parse(target.dataset.rules)
    },

    setFormSubmittable() {
        let errorCount = 0
        for (const input of this.inputs) {
            let assertion = this.assertField(input)
            if (!assertion.valid) {
                errorCount++
                break
            }
        }
        this.isFormSubmittable = errorCount === 0
    },

    serverValidationFailed(data) {
        this.isFormSubmittable = false
        data.field = data.field ? data.field : this.getLastInputName()
        this.fields[data.field].validation.valid = false
        this.fields[data.field].validation.error = data.message
    },

    getLastInputName() {
        return this.inputs[this.inputs.length - 1].name;
    },

    reset() {
        this.$root.querySelectorAll('form')[0].reset()
        // set checkboxes to initial value
        for (const input of this.$root.querySelectorAll('input[data-rules][type="checkbox"]')) {
            input.value = 0
        }

        for (const input of this.$root.querySelectorAll('input[data-rules][type="radio"]')) {
            input.checked = false;
        }

        this.success = false
        this.isFormSubmittable = false
        this.inputs = []
        this.fields = {}
        this.registerInputFields()
    },

    async submitPostData(path, formData) {

        this.isFormSubmittable = false
        formData.csrfToken = this.$refs.csrfToken.value

        try {
            let response = await fetch(path, {
                method: 'POST',
                headers: {
                    "x-requested-with": "XMLHttpRequest",
                    "content-type": "application/json",
                },
                body: JSON.stringify(formData)
            })

            let data = await response.json()
            if (data.error) {
                this.serverValidationFailed(data)
            } else {
                this.success = true
            }

            return data

        } catch (e) {
            return {
                error: true
            }
        }
    }
})

export default formValidator
