import autocompleter from "./autocompleter"

const autocompleterWithResource = (data) => ({
    ...autocompleter(data),

    convertResponseToData(data) {
        //reset everything
        this.setDefaultItems()
        this.$refs.list.scrollTop = 0
        this.selected = null //reset index with new list

        data = JSON.parse(data);
        data.forEach((attributes) => {
            this.items.push({
                label: attributes['label'],
                id: attributes['id'],
                value: attributes['name'],
                attributes: attributes,
            })
        })
    },

    setValue(item) {
        this.value = item.value
    },

})


export default autocompleterWithResource