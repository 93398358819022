import tagmanager from '../../../abstracts/tagmanager';

const accommodation = (action) => ({
    autocompleteSelected: null,
    dateStart: null,
    dateEnd: null,
    dateRange: null,
    form: null,
    action: '',
    season: 'winter',

    init() {
        this.action = action
        this.autocompleteSelected = this.$root.querySelector('[x-ref="selectedId"]')
        this.dateStart = this.$root.querySelector('[x-ref="start"]')
        this.dateEnd = this.$root.querySelector('[x-ref="end"]')
        this.dateRange = this.$root.querySelector('[x-ref="range"]')
        this.form = this.$refs.form
        this.season = this.form.dataset.season
        
        this.$nextTick(() => {
            this.setDefaultValues()
        })
    },
    
    submit() {
        try {
            const trackingData = {
                DateStart: this.dateStart.value,
                DateEnd: this.dateEnd.value,
                Period: this.getNumberOfNights(this.dateStart.value, this.dateEnd.value)
            }

            const value = this.autocompleteSelected?.value ?? this.action ?? null

            if (value) {
                trackingData[(this.season === 'sommer' ? 'Summerregion' : 'Skiresort')] = this.parseAccommodationNameAlias(value)
            }

            tagmanager.event('accommodation-search-form', trackingData)
        } catch(e) {
            // we have an issue in the form
            // but still we want to send form request
        }
        
        this.form.submit()
    },
    
    getNumberOfNights(dateStart, dateEnd) {
        const startDate = new Date(this.convertDateFormat(dateStart))
        const endDate = new Date(this.convertDateFormat(dateEnd))

        const timeDiff = endDate.getTime() - startDate.getTime()
        return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)) // number of nights
    },

    convertDateFormat(dateString) {
        const [day, month, year] = dateString.split(".")
        return `${year}-${month}-${day}`
    },

    setDefaultValues() {
        const storeUser = Alpine.store('user')
        const now = new Date();
        const dayOfTheWeekStart = 5

        if (storeUser.search.dateStart && new Date(this.convertDateFormat(storeUser.search.dateStart)) >= now) {
            this.dateStart.value = storeUser.search.dateStart
        } else {
            this.dateStart.value = now.getDay() === dayOfTheWeekStart
              ? this.formatDate(now)
              : this.formatDate(this.getNextDayOfWeek(now, dayOfTheWeekStart))
        }

        if (storeUser.search.dateEnd && storeUser.search.dateEnd > this.dateStart.value) {
            this.dateEnd.value = storeUser.search.dateEnd
        } else {
            const startDate = new Date(this.convertDateFormat(this.dateStart.value)) //end date should be after start date
            this.dateEnd.value = this.formatDate(this.getNextDayOfWeek(startDate, 0))
        }
    },

    autocompleteSelect(event) {
        this.action = event.detail.id // todo: if there is a new AJAX endpoint, `id` should be changed to `action`
        const trackingData = {
            Category: 'accommodation'
        }
        if (this.season === 'sommer') {
            trackingData['Summerregion'] = this.parseAccommodationNameAlias(event.detail.attributes[1])
        } else {
            trackingData['Skiresort'] = this.parseAccommodationNameAlias(event.detail.attributes[1])
        }
        
        tagmanager.event(`accommodation-search`, trackingData)
        
        if(this.dateStart.value) {
            return
        }

        this.$nextTick(() => {
            setTimeout(() => {
                this.dateRange.focus()
                this.dateRange.click()
            }, 600)
        })
    },

    parseAccommodationNameAlias(nameAlias) {
        const match = nameAlias.match(/^(?:\/sommer)?\/([^/]*)/)
        if (!match || !match[1]) {
            return ''
        }

        return match[1]
    },

    autocompleteReset() {
        this.action = action
    },

    getNextDayOfWeek(date, dayOfWeek){
        const resultDate = new Date(date.getTime())
        resultDate.setDate(date.getDate() + ((dayOfWeek + 7 - date.getDay()) % 7))
        return resultDate
    },

    formatDate(date) {
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date.getDate().toString().padStart(2, '0')

        return `${day}.${month}.${year}`
    }
})

export default accommodation
