export default class PhotoSwipeCaption {
    constructor(lightbox) {
        this.lightbox = lightbox
        lightbox.on('uiRegister', () => {
            this.initPlugin(this.lightbox.pswp)
        })
    }

    initPlugin(pswp) {
        pswp.ui.registerElement({
            name: 'custom-caption',
            order: 9,
            isButton: false,
            appendTo: 'root',
            html: 'Caption text',
            onInit: (el, pswp) => {
                pswp.on('change', () => {
                    const caption = pswp.currSlide?.data?.element?.dataset?.caption
                    el.innerHTML = caption || ''
                })
            }
        })
    }
}
