import Splide from '@splidejs/splide'
import scrollLock from '../abstracts/scrollLock'
import {BREAKPOINT_LG} from '../abstracts/responsive'

const contentMenu = () => {
    const identifier = {
        slider: 'js-contentmenu__slider',
        activeSlide: '.js-slide-active',
        openClass: 'tw-is-open',
        subItemTrigger: '.js-contentmenu__sub-trigger',
        subItem: '.js-contentmenu__sub',
    }

    const init = () => {
        initSlider()
        initSubItems()
    }

    const initSlider = () => {
        const elms = document.getElementsByClassName(identifier.slider)

        for (var i = 0; i < elms.length; i++) {
            const splide = new Splide(elms[i], {
                arrows: false,
                pagination: false,
                type: 'slide',
                autoWidth: true,
                drag: 'free',
                mediaQuery: 'min',
                breakpoints: {
                    [BREAKPOINT_LG]: {
                        destroy: true
                    }
                },
            })

            splide.mount()

            // scroll to first active slide
            const Slides = splide.Components.Slides.filter(identifier.activeSlide)
            if (Slides && Slides[0]) {
                splide.go(Slides[0].index)
            }
        }
    }

    const initSubItems = () => {
        document.querySelectorAll(identifier.subItemTrigger).forEach((el, i) => {
            el.addEventListener('click', toggleSubItem)
        })
    }

    const toggleSubItem = (e) => {
        e.preventDefault()
        const id = e.currentTarget.getAttribute('data-id')
        if (!id) {
            return
        }

        const target = document.querySelector(identifier.subItem + "[data-id=" + id + "]")
        scrollLock.toggle(!target.classList.contains(identifier.openClass))

        toggle(e, identifier.subItemTrigger)

        if(target.classList.contains(identifier.openClass)) {
            target.classList.remove(identifier.openClass)
            return
        }

        document.querySelectorAll(identifier.subItem).forEach((item) => {
            item.classList.remove(identifier.openClass)
        });

        target.classList.add(identifier.openClass)
    }

    /**
     * toggle a element and automatically disable all corresponding siblings
     * @param event
     * @param target
     */
    const toggle = (event, target) => {
        if(event.currentTarget.classList.contains(identifier.openClass)) {
            event.currentTarget.classList.remove(identifier.openClass)
            return
        }

        document.querySelectorAll(target).forEach((submenu) => {
            submenu.classList.remove(identifier.openClass)
        });

        event.currentTarget.classList.add(identifier.openClass)
    }

    // init on document load
    document.addEventListener('DOMContentLoaded', () => {
        init()
    })

    // expose init function to bergfex object
    bergfex = bergfex || {}
    bergfex.contentMenu = {init}
}

export default contentMenu
