let body = null
window.bergfex = window.bergfex ||{}
window.bergfex.scrollLock = false

const lock = () => {
    if (window.bergfex.scrollLock) {
        return
    }

    setTimeout(() => {
        const body = getBody()
        body.style.overflow = 'hidden'
        window.bergfex.scrollLock = true
    }, 100)
}

const unlock = () => {
    if (!window.bergfex.scrollLock) {
        return
    }

    const body = getBody()
    body.style.removeProperty('overflow')

    setTimeout(() => {
        window.bergfex.scrollLock = false
    }, 1)
}

const toggle = (state) => {
    state ? lock() : unlock()
}

const getBody = () => {
    if (!body) {
        body = document.querySelector('body')
    }

    return body
}

export default {lock, unlock, toggle}
